<template>
  <div class="personal">
      <p class="personal_title">基本信息</p>
      <!-- <img class="personal_img" :src="userInfo.headPic"> -->
      <el-upload
        class="avatar-uploader"
        action="https://up-z1.qiniup.com/"
        :show-file-list="false"
        :data="qiniuobj"
        :on-success="successImgUpload"
        :on-error="errorImgUpload"
        :before-upload="beforeAvatarUpload">
        <img class="personal_img" :src="userInfo.headPic">
      </el-upload>
      <p class="personal_tips">点击修改头像</p>
      <div class="personal_form">
        <el-form label-position="left" ref="form" :model="form" label-width="80px">
          <el-form-item label="姓名">
            <el-input v-model="form.name" placeholder="请输入您的姓名" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-radio-group v-model="form.sex">
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="手机号" >
            <div style="display:flex;" class="form-item">
              <p>{{userInfo.phone}}</p> 
              <el-button type="primary" @click="exchangeMobile">更换手机号</el-button>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">保存信息</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 更换手机号 -->
      <el-dialog
          title="更换手机号"
          :visible.sync="ispay"
          width="30%"
          :before-close="payClose">
            <div class="el_radio_div">
                <el-form :model="ruleForm"  ref="ruleForm" label-width="8em" class="demo-ruleForm">
                    <!-- :rules="rules" -->
                    <el-form-item label="原来的手机号" prop="oldMobile">
                        <div class="form_item">
                            <el-input v-model="userInfo.phone" :disabled="true"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="验证码" prop="code">
                        <div class="form_item_code">
                            <el-input v-model="phoneCode" maxlength="6"></el-input>
                            <el-button type="primary" @click="getcode()" :disabled="countdown=='获取验证码'?false:true">{{countdown}}</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
          <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="openEXchange">确 定</el-button>
          </span>
      </el-dialog>
      <!-- 更换手机号 -->
      <el-dialog
          title="更换手机号"
          :visible.sync="isShow"
          width="30%"
          :before-close="ShowClose">
            <div class="el_radio_div">
                <el-form :model="ruleForm"  ref="ruleForm" label-width="8em" class="demo-ruleForm">
                    <!-- :rules="rules" -->
                    <el-form-item label="新的的手机号" prop="oldMobile">
                        <div class="form_item">
                            <el-input v-model="ruleForm.nowMobile"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="验证码" prop="code">
                        <div class="form_item_code">
                            <el-input v-model="ruleForm.nowCode" maxlength="6"></el-input>
                            <el-button type="primary" @click="getcodeTwo()" :disabled="countdownTwo=='获取验证码'?false:true">{{countdownTwo}}</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
          <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="chosepay">确 定</el-button>
          </span>
      </el-dialog>
  </div>
  
</template>

<script>
import { upd_user_info,get_user_info } from "@/api/myself";
import { get_video_token } from "@/api/exam";
import { send_verification_code,verify_phone_number,change_user_phone  } from "@/api/other";

export default {
  name: 'personal',
  data(){
    return{
        form: {
            name: '',
            sex: '1',
        },
        userInfo:{},
        qiniuobj:{},
        file:'',
        ispay:false,
        phoneCode:'',
        downtime:60,
        countdown:'获取验证码',
        countdownloading:false,
        ruleForm:{
            nowMobile:'',
            nowCode:'',
        },
        isShow:false,
        countdownTwo:'获取验证码',
        downtimeTwo:60,
    }
  },
  mounted(){
    this.userInfo = this.$store.state.userInfo
    console.log(this.userInfo)
    this.form.name=this.$store.state.userInfo.nickname
    // this.form.sex=this.$store.state.userInfo.sex.toString()
    // this.getVideoToken()
  },
   watch: {
    "$store.state.userInfo"(newval, olval) {
      this.userInfo = newval
    },
  },
  methods:{
    // 取消更换弹窗
    payClose(){
        this.ispay = false
    },
    // 取消更换弹窗
    ShowClose(){
        this.isShow = false
    },
    chosepay(){
      var obj={
        phone:this.ruleForm.nowMobile,
        code:this.ruleForm.nowCode
      }
      change_user_phone(obj).then(res=>{
        console.log(res)
        if(res.data.code==0){
            this.$message.success('校验成功');
            this.isShow=false
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(error=>{
        console.log(error)
      })
    },
    // 
    exchangeMobile(){
      this.ispay=true
    },
    getcode(){
        var obj={
            phone:this.userInfo.phone
        }
        send_verification_code(obj).then(res=>{
            console.log(res)
            if(res.data.code==0){
                    this.countDownTime()
                    this.countdownloading = true
                }else{
                    this.$message.error(res.data.msg);
                }
        }).catch(error=>{
            console.log(error)
        })
    },
    getcodeTwo(){
        var obj={
            phone:this.ruleForm.nowMobile
        }
        send_verification_code(obj).then(res=>{
            console.log(res)
            if(res.data.code==0){
                this.countDownTimeTwo()
                this.countdownloading = true
            }else{
                this.$message.error(res.data.msg);
            }
        }).catch(error=>{
            console.log(error)
        })
    },
    // 倒计时
    countDownTimeTwo(){
        let that = this
        let downtimeTwo = that.downtimeTwo
        setTimeout(()=>{
            if(that.downtimeTwo>0){
                that.downtimeTwo = downtimeTwo-1
                that.countdownTwo = that.downtimeTwo+'秒后重试'
                that.countDownTime()
            }
            else{
                that.downtimeTwo = 60
                this.countdownloading = false
                that.countdownTwo='获取验证码'
            }
        },1000)
    },
    // 倒计时
    countDownTime(){
        let that = this
        let downtime = that.downtime
        setTimeout(()=>{
            if(that.downtime>0){
                that.downtime = downtime-1
                that.countdown = that.downtime+'秒后重试'
                that.countDownTime()
            }
            else{
                that.downtime = 60
                this.countdownloading = false
                that.countdown='获取验证码'
            }
        },1000)
    },
    // 校验是否本人操作
    openEXchange(){
        var obj={
            phone:this.userInfo.phone,
            code:this.phoneCode
        }
        verify_phone_number(obj).then(res=>{
            console.log(res)
            if(res.data.code==0){
                this.$message.success('校验成功');
                this.ispay=false
                this.isShow=true
                this.countdown='获取验证码'
            }else{
                this.$message.error(res.data.msg);
            }
        }).catch(error=>{
            console.log(error)
        })  
    },
    // 提交
    onSubmit() {
      var obj = {
        bornDate:'',
      }
      if(this.file){
        obj.headPic = this.file
      }
      if(this.form.sex){
        obj.sex = this.form.sex
      }
      if(this.form.name){
        obj.nickName = this.form.name
      }
      console.log(obj)
      upd_user_info(obj)
      .then(res=>{
        if(res.data.code == 0){
          this.$message.success('修改成功');
          this.getUserInfo()
        }
        else{
          this.$message.error(res.data.msg);
        }
      })
    },
    // 获取七牛token
    getVideoToken(){
      get_video_token()
      .then(res=>{
        this.qiniuobj = res.data.result
      })
    },
    // 提交图片
    beforeAvatarUpload(file){
      var that = this
      console.log(file.type)
      if (file.type.indexOf('image') == -1) {
        that.$message.error('只能上传图片格式!');
        return false
      }
      return
    },
    // 获取用户信息
    getUserInfo(){
      get_user_info().then(res=>{
        if(res.data.code==0){
          this.$store.commit("setuserInfo",{userInfo:res.data.result})
          this.userInfo=res.data.result
          this.$router.go(0)
        }
        else{
          this.$message.error(res.data.msg);
        }
      }).catch(error=>{
        console.log(error)
      })
    },
    // 上传图片成功
    successImgUpload(response, file, fileList){
      var that = this
      console.log(response, file, fileList)
      this.userInfo.headPic = 'http://qiniu.bjyzr.com/'+response.key
      this.file = response.key
      that.getVideoToken()
    },
    // 上传图片失败
    errorImgUpload(){
      this.$message.error('文件上传失败,请重新上传')
    },
  }
}
</script>
